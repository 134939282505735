<template>
  <section>
    <div class="px-4">
      <DataTable
        :value="grupos"
        v-model:expandedRows="expandedRows"
        dataKey="id"
        @rowExpand="onRowExpand"
        responsiveLayout="scroll"
      >
        <Column :expander="true" headerStyle="width: 3rem; background-color: white" />
        <Column field="name" headerStyle="background-color: white; padding: 0px !important"></Column>
        <Column v-if="$can('usuarios.usuarios.access')" headerStyle="background-color: white; padding: 0px !important">
          <template #body="slotProps">
            <Button @click="findGroup(slotProps.data.id)" icon="pi pi-pencil" class="p-button-rounded p-button-text" />
          </template>
        </Column>
        <template #expansion="slotProps">
          <div v-if="usuariosCount === 0">
            <p>Este grupo aun no cuenta con usuarios</p>
          </div>
          <div v-else>
            <DataTable v-if="slotProps.data" :value="usuariosByGroup" responsiveLayout="scroll">
                <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="persona.full_name" header="Nombre"></Column>
                <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="user_name" header="Usuario"></Column>
                <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="persona.email" header="Email"></Column>
                <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="persona.telefono" header="Télefono"></Column>
            </DataTable>
            <Paginator
              v-model:first="offsetUsers"
              :rows="limitUsers"
              :totalRecords="usuariosCount"
              :rowsPerPageOptions="[2,10,20,30, 100, 500]"
              @page="onPageUsers($event)"
            />
          </div>
        </template>
      </DataTable>
      <Paginator v-model:first="offset"
        :rows="limit"
        :totalRecords="totalGrupos"
        :rowsPerPageOptions="[2,10,20,30, 100, 500]"
        @page="onPage($event)"
      />
    </div>
    <Dialog :showHeader="false" :closable="false" class="shadow-md rounded-lg" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
      <div class="w-full">
        <div class="flex justify-center w-full">
          <div>
            <div class="flex justify-center">
              <img class="m-4" src="../../../../../../../../assets/images/icons/modal-createpub-icon.png" alt="">
            </div>
            <p class="font-medium text-2xl" style="color: #7F7E81">
              Editar ruta de grupo
            </p>
          </div>
        </div>
        <div v-if="actualizo.updatedAt" class="w-full flex justify-center text-center text-xs text-gray-400">
          <i class="pi pi-refresh text-xs mr-2"></i>
          Ultima actualización por: {{ actualizo.updatedBy }} el día {{ dayjs(actualizo.updatedAt).format('YYYY/MM/DD') }} a las {{ dayjs(actualizo.updatedAt).format('HH:mm:ss') }}
        </div>
        <div class="my-4">
          <label for="nameGroup">Nombre</label>
          <InputText v-model="nameGroup" id="nameGroup" class="w-full mt-2" type="text" />
        </div>
        <div class="my-4">
          <label for="nameGroup">Ruta principal</label>
          <div class="w-full mt-2">
            <Dropdown class="w-full rounded-md border-gray-300" v-model="routeName" :options="listadoMenusPpales" optionLabel="action" optionValue="action" :filter="true" placeholder="Seleccione" :showClear="true">
            </Dropdown>
          </div>
        </div>
      </div>
      <div class="w-full grid grid-cols-2 gap-2">
        <Button label="Cancelar" style="background-color: #F8F8F8;" @click="closeModal" class="p-button-text rounded-lg"/>
        <Button label="Guardar" style="background-color: #1C3FAA"  @click="editarGrupo()" autofocus class="rounded-lg" />
      </div>
    </Dialog>
  </section>
</template>
<script>
import { onMounted, ref, computed, watch } from 'vue'
import GruposService from '../../../../../services/grupos.service'
import UserSerivce from '../../../../../services/usuarios.service'
import usuariosStore from '../../../../../stores/usuarios.store'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
export default {
  name: 'TablaUsuarios',
  setup () {
    /** ref **/
    const _GruposService = ref(new GruposService())
    const _UserSerivce = ref(new UserSerivce())
    const grupos = ref([])
    const grupoSelected = ref(0)
    const limit = ref(10)
    const limitUsers = ref(10)
    const offset = ref(0)
    const offsetUsers = ref(0)
    const page = ref(0)
    const pageUsers = ref(0)
    const totalGrupos = ref(0)
    const usuariosByGroup = ref([])
    const usuariosCount = ref(0)
    const grupoUsuariosId = ref(0)
    const routeName = ref('')
    const nameGroup = ref('')
    const expandedRows = ref([])
    const displayModal = ref(false)
    const grupoEditarId = ref(0)
    const actualizo = ref({})
    /** computed **/
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        grupo: ''
      }
    })
    const usersParams = computed(() => {
      return {
        offset: offsetUsers.value,
        page: pageUsers.value,
        limit: limitUsers.value,
        group: 0
      }
    })
    const listadoMenusPpales = computed(() => usuariosStore.getters._listado_menus_ppales)
    const nombreFiltrado = computed(() => usuariosStore.getters._filtro_tabla_grupos)
    const todosGrupos = computed(() => usuariosStore.getters._listado_todos_grupos)
    /** methods **/
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      listarGrupos()
    }
    const onPageUsers = ({ first, page, pageCount, rows }) => {
      limitUsers.value = rows
      listarUsuariosPorGrupo()
    }
    const listarUsuariosPorGrupo = () => {
      usersParams.value.group = grupoUsuariosId.value
      _UserSerivce.value.getByGroup(usersParams.value).then(({ data }) => {
        usuariosByGroup.value = data.rows
        usuariosCount.value = data.count
      })
    }
    const listarGrupos = () => {
      _GruposService.value.paginate(params.value).then(({ data }) => {
        grupos.value = data.rows
        totalGrupos.value = data.count
        grupos.value.map(a => {
          a.usuarios = []
        })
      })
    }
    const onRowExpand = (event) => {
      expandedRows.value = []
      expandedRows.value.push(event.data)
      grupoUsuariosId.value = parseInt(event.data.id)
      listarUsuariosPorGrupo()
    }
    const findGroup = (id) => {
      grupoEditarId.value = id
      _GruposService.value.find(id).then(({ data }) => {
        if (data.routeName) {
          nameGroup.value = data.name
          routeName.value = data.routeName
          if (data.actualizo) {
            actualizo.value = {
              updatedAt: data.updatedAt,
              updatedBy: data.actualizo.persona.full_name
            }
          }
        }
        displayModal.value = true
      })
    }
    const closeModal = () => {
      routeName.value = ''
      displayModal.value = false
    }
    const editarGrupo = () => {
      const array = todosGrupos.value
      const index = array.findIndex(a => a.id === grupoEditarId.value)
      array.splice(index, 1)
      if (array.some(a => a.name.toUpperCase() === nameGroup.value.toUpperCase())) {
        Swal.fire(
          'Error',
          'Este nombre de grupo ya se encuentra registrado',
          'warning'
        )
      } else {
        const object = {
          id: grupoEditarId.value,
          routeName: routeName.value,
          name: nameGroup.value
        }
        _GruposService.value.put(object).then((data) => {
          if (data.error) {
            Swal.fire(
              'Error',
              'Error al editar grupo, intente nuevamente',
              'error'
            ).then(() => {
              closeModal()
            })
          } else {
            closeModal()
            Swal.fire(
              'Exito',
              'Grupo editado satisfactoriamente',
              'success'
            ).then(() => {
              listarGrupos()
            })
          }
        })
      }
    }
    /** watch **/
    watch(grupoSelected, (nuevo, viejo) => {
      if (viejo) {
        nuevo.active ? (viejo.active ? viejo.active = false : viejo.active = true) : console.log('')
      }
    })
    watch(nombreFiltrado, (nuevo, viejo) => {
      if (nuevo) {
        params.value.grupo = nuevo
      } else {
        params.value.grupo = ''
      }
      listarGrupos()
    })
    /** onMounted **/
    onMounted(() => {
      usuariosStore.commit('limpiarUsuarioData', {})
      listarGrupos()
    })
    /** return **/
    return {
      grupos,
      onPage,
      limit,
      offset,
      usuariosByGroup,
      nombreFiltrado,
      expandedRows,
      totalGrupos,
      onRowExpand,
      limitUsers,
      offsetUsers,
      pageUsers,
      onPageUsers,
      usuariosCount,
      findGroup,
      listadoMenusPpales,
      routeName,
      displayModal,
      closeModal,
      editarGrupo,
      nameGroup,
      actualizo,
      dayjs
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
  }

  ::v-deep(.rowStyle) {
    // box-shadow: 3px 3px 10px 10px #0000001A;
    border-radius: 5px;
    margin: 5px !important;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 4px 18px #0000001A;
    opacity: 1;
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
